import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

export function Features() {
    const contentStyle = {
        fontFamily: 'Roboto, sans-serif', // This matches the font in the image
        fontWeight: '300', // Default font weight
        padding: '20px', // Adjust the padding as needed
        backgroundColor: 'white', // Assuming a white background
        lineHeight: '1.5', // For better readability
        fontSize: '14px', // Adjust the font size as needed
        color: 'black', // Adjust the color as needed, assuming a dark grey text
        margin:'10px',
        borderRadius:'10px',
       
      };
    
      const listStyle = {
        listStyleType: 'none', // Removes the default list bullet points
        paddingLeft: '20px', // Adds padding inside the list for custom bullets or numbering
      };
    
      const listItemStyle = {
        marginBottom: '10px', // Spacing between list items
      };
    
      const bulletStyle = {
        fontWeight: 'bold', // Bold bullet points
        color: '#0056b3', // Blue color for the bullet points, adjust as needed
      };
      const linkStyle = {
        textDecoration: 'none', // Removes the underline
      };

      const indicatorsState = useSelector((state) => state.privateIndicators);
      const indicatorsArray = Object.values(indicatorsState.value);
      const nonPrivateCount = indicatorsArray.filter(item => item.is_private === 0).length;
    
      return (
        <div style={contentStyle}>
          <p>The OICStat Database is a user-friendly platform and equipped with the following features:</p>
          <ul style={listStyle}>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Multilinguality:</span> The OICStat Database is available in three official languages of the OIC, namely English, Arabic and French.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Accordion Menu:</span> The OICStat Database shows all data query steps, namely "Countries", "Categories & Indicators", "Years", and "Format" on the same page under an accordion menu – a vertically stacked list of headers that can be clicked to hide or show the content within – before previewing/exporting the data.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Major Country Groupings:</span> The "Countries" menu offers users options to easily select member countries of some major country groupings; all of whose members are OIC member countries, OIC groups namely OIC-Asia, OIC-Africa and OIC-Arab which are followed by economic and income groups, namely OIC Least Developed Countries (OIC-LDCs), OIC Land-Locked Developing Countries (OIC-LLDCs), OIC-Low Income, OIC-Lower Middle Income, OIC-Upper Middle Income, and OIC-High Income and as of last class is Geographical Group namely OIC-East Asia and Pasific (OIC-EAP), OIC Europe and Central Asia (OIC-ECA), OIC Latin America and Caribbean (OIC-LAC), OIC Middle East and North Africa (OIC-MENA), OIC South Asia (OIC-SA) and OIC Sub-Saharan Africa(OIC-SSA).Ticking a country grouping will automatically select the OIC member countries with a membership in the chosen grouping. It is also possible to exclude members of a specific country grouping from a selection of countries by clicking twice on that grouping.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Quick Access to "OIC Countries in Figures" Module:</span> Clicking the "i" icon next to each OIC country name under the "Countries" menu will direct the users to the related country profile under the "<a style={linkStyle} href="https://www.sesric.org/cif-home.php">OIC Countries in Figures</a>" module.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Browse and Search Indicators:</span> The OICStat Database hosts {nonPrivateCount} indicators on various socio-economic, financial, and cross-cutting themes presented under the "Categories & Indicators" menu. To search for the indicator(s) of interest, users can browse through the categories or type the keyword(s) in the 'Enter keywords for search' box and click on the magnifying glass icon.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Download by Category:</span> Users have the option to download a category as a whole – all of its indicators – with data dating back to 1970. To access the "Download by Category" page, users can open the desired category under the "Categories & Indicators" menu and click on the "Download the Entire Dataset" link.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Multiple Categorisation:</span> Some indicators may appear under multiple categories listed under the "Categories & Indicators" menu due to their relevance.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Indicators, Definitions, and Metadata:</span> Users can see the full list of indicators available in the OICStat Database with their definitions by visiting the "<Link style={linkStyle} to={"/indicators_list"}>Indicators List</Link>" page. Users have also access to a short metadata of an indicator containing information on its "Definition", "Source", "Data Period", and "Last Update Date" by clicking the "i" icon next to each indicator name under the "Categories & Indicators" menu.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Last Year Available Data:</span> The "Last Year Available" option under the "Years" menu enables the users to access the most recent year data only, for the indicators selected under the "Categories & Indicators" menu. Users also have the "Last 5 Years" option to submit queries to the OICStat Database for the most recent five years.
            </li>
            <li style={listItemStyle}>
                <span style={bulletStyle}>Preview & Download:</span> After making their selections from the aforementioned four steps in the accordion menu and clicking the "Submit" button, users can preview the query result and download it in CSV format.
            </li>
          </ul>
        </div>
      );
}