import TabMenu from "./TabMenu";
import { createBrowserRouter, Link, RouterProvider } from "react-router-dom";
import logo from '../../assets/images/oicstat-logo.png';
import { Row, Col } from "antd";
import { useEffect } from "react";
import { useSelector } from 'react-redux';

export function Home() {

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    margin:'10px',
    borderRadius:'10px',
    backgroundColor: 'white',
    lineHeight:'22px'
  };

  const logoStyle = {
    marginRight: '20px',
    marginBottom: '10px',
    width:'120px'
  };

  const textStyle = {
    lineHeight: '22.5px', 
    fontWeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '300', 
   
  };
  const boldStyle = {
    fontWeight: 'bold',
  };

  const indicatorsState = useSelector((state) => state.privateIndicators);
  const indicatorsArray = Object.values(indicatorsState.value);
  const nonPrivateCount = indicatorsArray.filter(item => item.is_private === 0).length;

  
  return (
    
    <Row style={containerStyle}>
      <Col>
        <img src={logo} alt="OICStat Logo" style={logoStyle} />
      </Col>
      
      <Col style={{width:'90%'}}>
        <p style={textStyle}>
          <strong >The OIC Statistics (OICStat) Database</strong> is the first and only international statistics repository on the 
          member countries of the <a href="https://www.oic-oci.org/" target="_blank">Organisation of Islamic Cooperation (OIC)</a>. Enriched with the experience inherited from its predecessor, namely 
          Basic Social and Economic Indicators (BASEIND) Database, the <strong >OICStat</strong>  Database is periodically maintained to ensure the dissemination of 
          the most up-to-date data through the inclusion of new indicators and categories on the OIC member countries. Equipped with more "<Link to={"/features"}>user-friendly" features,</Link> the OICStat Database currently hosts {nonPrivateCount} <Link to={"/indicators_list"}>indicators</Link> under 27 categories with data dating back to 1970.
        </p>
      </Col>
    </Row>
  );
}
