import React from 'react';
import { Navbar, Nav, Container, NavDropdown, Row, Col } from 'react-bootstrap';
import { CaretRightOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/external/externalTopbar.css';
import SesricLogo from '../../assets/images/sesric_logo.png';
export function ExternalTopbar () {

  return (
    < >
      {/* Top bar for language, date, and social media */}
      {/* <Navbar style={{ backgroundColor: '#2F83C5', color: 'white', height:'40px' }} variant="dark">
            <Container className="justify-content-between">
              <Nav>
                <Nav.Link href="/" className="text-white">English</Nav.Link>
                <Nav.Link href="/ar" className="text-white">العربية</Nav.Link>
                <Nav.Link href="/fr" className="text-white">Français</Nav.Link>
              </Nav>
              <Navbar.Text className="text-white">
                14 March 2024, Thursday
              </Navbar.Text>
              <Nav>
                <Nav.Link href="https://twitter.com/sesric" className="text-white">
                  <FontAwesomeIcon icon={faTwitter} />
                </Nav.Link>
                <Nav.Link href="https://www.instagram.com/sesric1978" className="text-white">
                  <FontAwesomeIcon icon={faInstagram} />
                </Nav.Link>
                <Nav.Link href="https://www.youtube.com/@sesric1978" className="text-white">
                  <FontAwesomeIcon icon={faYoutube} />
                </Nav.Link>
                <Nav.Link href="mail-subscription-form.php" className="text-white">
                  <FontAwesomeIcon />
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>

           */}
      <Navbar bg="light" expand="lg" style={{ minHeight: '100%' }}>
        <Container fluid className="justify-content-between" >
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" >
            <Nav className="mx-auto"  >
              <Navbar.Brand href="https://www.sesric.org/index.php">
                <img src={SesricLogo} width="80" height="80" alt="SESRIC Logo" />
              </Navbar.Brand>
              <NavDropdown title="SESRIC" id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md={6} className="mega-menu-column">
                      <h6 className="dropdown-header">About</h6>
                      <div className="dropdown-divider" />

                      <NavDropdown.Item href="https://www.sesric.org/about-oic.php" className="menu-item"> <CaretRightOutlined /> About OIC</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-about.php" className="menu-item"> <CaretRightOutlined /> About SESRIC</NavDropdown.Item>

                    </Col>
                    <Col md={6} className="mega-menu-column">
                      <h3 className="dropdown-header">Departments</h3>

                      <div className="dropdown-divider" />
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-statistics.php" className="menu-item"> <CaretRightOutlined /> Statistics & Information</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-research.php" className="menu-item"> <CaretRightOutlined /> Economic & Social Research</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-training.php" className="menu-item"> <CaretRightOutlined /> Training & Technical Cooperation</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-publication.php" className="menu-item"> <CaretRightOutlined /> Publication & IT</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-admin.php" className="menu-item"> <CaretRightOutlined /> Administration & Finance</NavDropdown.Item>

                    </Col>
                  </Row>
                </Container>
              </NavDropdown>
              <NavDropdown title="Statistics and Information" id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row>
                    {/* First Column */}
                    <Col xs={12} md={2} className="mega-menu-column">
                      <h6 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Databases, Directories and Rosters</h6>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="http://oicstat.sesric.org" className="menu-item"> <CaretRightOutlined />OIC Statistics Database (OICStat)</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/sesric_covid_19.php" className="menu-item"> <CaretRightOutlined />SESRIC Covid-19 Pandemic Database</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/databases-nso.php" className="menu-item"> <CaretRightOutlined />Directory of National Statistical</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oicdir.php" className="menu-item"> <CaretRightOutlined />Directory of National Institutions</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/rose.php" className="menu-item"> <CaretRightOutlined />Roster of Statistics Experts (ROSE)</NavDropdown.Item>
                    </Col>

                    {/* Second Column */}
                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Data Dissemination and Visualisation Tools</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/cif-home.php" className="menu-item"> <CaretRightOutlined />OIC Countries in Figures (OIC-CIF)</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-ranker.php" className="menu-item"> <CaretRightOutlined />OIC Ranker</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-tbf.php" className="menu-item"> <CaretRightOutlined /> OIC Top/Bottom Finder (OIC-TBF)</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/smc.php" className="menu-item"> <CaretRightOutlined /> SESRIC Motion Charts (SMC)</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-syb.php" className="menu-item"> <CaretRightOutlined /> Statistical Yearbook on OIC Member </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oso.php" className="menu-item"> <CaretRightOutlined /> OIC Statistical Outlook</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.oicstatcom.org/didyouknow.php" className="menu-item"> <CaretRightOutlined /> Did You Know? (DYK) </NavDropdown.Item>
                    </Col>

                    {/* Continue with the other columns similarly */}
                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Statistical Capacity Building (StatCaB) Programme</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.oicstatcom.org/statcab.php" className="menu-item"> <CaretRightOutlined />About StatCaB Programme</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.oicstatcom.org/webinar-series.php" className="menu-item"> <CaretRightOutlined />Statistical Experience Sharing Webinar Series</NavDropdown.Item>
                    </Col>

                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">OIC Statistical Commission (OIC-StatCom)</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.oicstatcom.org/" className="menu-item"> <CaretRightOutlined />About OIC-StatCom</NavDropdown.Item>
                    </Col>

                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Sustainable Development Goals (SDGs)</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-sdgs.php" className="menu-item"> <CaretRightOutlined />Annual Progress Report on Prioritised SDGs</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=456" className="menu-item"> <CaretRightOutlined />Prioritisation of SDGs by OIC Member Countries</NavDropdown.Item>
                    </Col>

                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Cooperation with International and Regional Organisations</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="http://www.oicstatcom.org/tqs.php" className="menu-item"><CaretRightOutlined />TQS and TQS-Y Integration Project</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/event-detail.php?id=2325" className="menu-item"><CaretRightOutlined />Subgroup on Gender Statistics Training (SGGST)</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://unstats.un.org/unsd/ccsa/" className="menu-item"><CaretRightOutlined />Committee for the Coordination of Statistical Activities</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://uneuropecentralasia.org/en/regional-coordination-group-data-and-statistics" className="menu-item"><CaretRightOutlined />Regional Coordination Group on Data and Statistics</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://unstats.un.org/gist/" className="menu-item"><CaretRightOutlined />Global Network of Institutions for Statistical Training (GIST)</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.tossd.org/" className="menu-item"><CaretRightOutlined />International Forum on TOSSD (IFT)</NavDropdown.Item>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>
              <NavDropdown title="Economic and Social Research" id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row style={{ justifyContent: 'center' }}>
                    {/* Reports for Ministerial Conferences */}
                    <Col md={2} className="mega-menu-column">
                      <h6 className="dropdown-header">Reports for Ministerial Conferences</h6>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/economic-outlook.php"><CaretRightOutlined /> OIC Economic Outlook</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/international-tourism.php"><CaretRightOutlined /> International Tourism in the OIC Countries: Prospects and Challenges</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-health.php"><CaretRightOutlined /> OIC Health Report</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/agriculture-food-security.php"><CaretRightOutlined /> Agriculture and Food Security in OIC Member Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-water-report.php"><CaretRightOutlined /> OIC Water Report</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-labour-market-report.php"><CaretRightOutlined /> OIC Labour Market Report</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-education-scientific.php"><CaretRightOutlined /> Education and Scientific Development in OIC Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-environment-report.php"><CaretRightOutlined /> OIC Environment Report</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-state-youth.php"><CaretRightOutlined /> State of Youth in OIC Member States</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-women-development-report.php"><CaretRightOutlined /> OIC Women and Development Report</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-family-values.php"><CaretRightOutlined /> Safeguarding Family Values and the Institution of Marriage in OIC Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-state-elderly-report.php"><CaretRightOutlined /> State of Elderly Report</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-state-children-report.php"><CaretRightOutlined /> State of Children Report</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-disabilities-special-needs.php"><CaretRightOutlined /> People with Disabilities and Special Needs</NavDropdown.Item>
                      </ul>
                    </Col>
                    {/* Strategic Programmes and Plans of Action */}
                    <Col md={2} className="mega-menu-column">
                      <h6 className="dropdown-header">Strategic Programmes and Plans of Action</h6>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-2025-programme-of-action.pdf"><CaretRightOutlined /> OIC-2025 Programme of Action</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/OIC-Strategic-Health-Programme-of-Action-2014-2023.pdf"><CaretRightOutlined /> OIC Health Strategic Programme of Action 2014-2023</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-labour-strategy-2025.pdf"><CaretRightOutlined /> OIC Labour Strategy 2025</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/strategic-roadmap-islamic-tourism.pdf"><CaretRightOutlined /> Strategic Roadmap for Development of Islamic Tourism</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/OPAAW.PDF"><CaretRightOutlined /> OIC Plan of Action for the Advancement of Women</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/Youth-Strategy.pdf"><CaretRightOutlined /> OIC Youth Strategy</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-strategy-elderly.pdf"><CaretRightOutlined /> OIC Strategy on the Elderly</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-plan-of-action-disability.pdf"><CaretRightOutlined /> OIC Plan of Action on People with Disabilities</NavDropdown.Item>
                      </ul>
                    </Col>
                    {/* Joint & Commissioned Reports */}
                    <Col md={2} className="mega-menu-column">
                      <h6 className="dropdown-header">Joint & Commissioned Reports</h6>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=546"><CaretRightOutlined /> South-South Cooperation and the Gender Equality</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=525"><CaretRightOutlined /> Potential Impacts of the AfCFTA On Selected OIC Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=533"><CaretRightOutlined /> South-South in Action: Transforming Potentials into Shared Prosperity</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=485"><CaretRightOutlined /> Human Rights Standards and Institution in OIC Member States</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=378"><CaretRightOutlined /> Mapping Turkey’s Resource Centres</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=336"><CaretRightOutlined /> Measurement of Poverty in OIC Member Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=321"><CaretRightOutlined /> Managing Disasters and Conflicts in OIC Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=328"><CaretRightOutlined /> Integration of Waqf and Islamic Microfinance for Poverty Reduction</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/event-detail.php?id=1136"><CaretRightOutlined /> The Atlas of Islamic World Science and Innovation</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://sesricdiag.blob.core.windows.net/sesric-site-blob/files/article/653.pdf"><CaretRightOutlined /> D-8 Economic Outlook Report</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://sesricdiag.blob.core.windows.net/sesric-site-blob/files/article/654.pdf"><CaretRightOutlined /> International Tourism in D-8 Member States: Status and Prospects</NavDropdown.Item>
                      </ul>
                    </Col>
                    {/* Ad-hoc Research */}
                    <Col md={2} className="mega-menu-column">
                      <h6 className="dropdown-header">Ad-hoc Research</h6>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=545"><CaretRightOutlined /> Socio-Economic Impacts of COVID-19 Pandemic in OIC Member Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=538"><CaretRightOutlined /> Halal Industry in OIC Member Countries: Challenges and Prospects</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=535"><CaretRightOutlined /> Urban Development in OIC Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=472"><CaretRightOutlined /> Achieving Peace and Security in a World of Turmoil</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=483"><CaretRightOutlined /> Financing for Development: Alternative Perspectives</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=477"><CaretRightOutlined /> Global Muslim Diaspora: Country Reports</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=412"><CaretRightOutlined /> Humanitarian Crises Report in OIC Countries</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/managing-natural-disasters.pdf"><CaretRightOutlined /> Managing Natural Disasters in Afghanistan</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/socio-economic-development.pdf"><CaretRightOutlined /> Somalia: Overview of Socio-Economic Development</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/understanding-radicalism.pdf"><CaretRightOutlined /> Towards Understanding Radicalism & Violent Extremism</NavDropdown.Item>
                      </ul>
                    </Col>
                    {/* OIC Outlook Reports */}
                    <Col md={2} className="mega-menu-column">
                      <h6 className="dropdown-header">OIC Outlook Reports</h6>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-outlook.php"><CaretRightOutlined /> OIC Outlook Reports</NavDropdown.Item>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>

              <NavDropdown title="Training and Technical Cooperation" id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row style={{ justifyContent: 'center' }}>
                    {/* SESRIC Capacity Building Programmes */}
                    <Col md={4} className="mega-menu-column">
                      <h3 className="dropdown-header">SESRIC Capacity Building Programmes</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item href="https://www.sesric.org/tcd-cab.php" className="menu-item"> <CaretRightOutlined /> All Programmes</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-agricab.php" className="menu-item"> <CaretRightOutlined /> Agriculture</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-ctp.php" className="menu-item"> <CaretRightOutlined /> Cotton</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-water.php" className="menu-item"> <CaretRightOutlined /> Water</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-oic-drm.php" className="menu-item"> <CaretRightOutlined /> Risk Management</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-environment.php" className="menu-item"> <CaretRightOutlined /> Environment</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-centralbanks.php" className="menu-item"> <CaretRightOutlined /> Central Banks</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/tcd-cab.php" className="menu-item"> <CaretRightOutlined /> more ...</NavDropdown.Item>
                    </Col>

                    {/* Technical Cooperation */}
                    <Col md={4} className="mega-menu-column">
                      <h3 className="dropdown-header">Technical Cooperation</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item href="https://www.sesric.org/technical_cooperation.php" className="menu-item"> <CaretRightOutlined /> Main Page</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/reverse-linkage.php" className="menu-item"> <CaretRightOutlined /> Reverse Linkages</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/tcd-activities-projects.php" className="menu-item"> <CaretRightOutlined /> Activities & Projects</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/tcd-networks.php" className="menu-item"> <CaretRightOutlined /> Networks</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/tcd-portals.php" className="menu-item"> <CaretRightOutlined /> Portals</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/mous-agreements.php" className="menu-item"> <CaretRightOutlined /> MoUs</NavDropdown.Item>
                    </Col>

                    {/* OIC-VET Programme */}
                    <Col md={4} className="mega-menu-column">
                      <h3 className="dropdown-header">OIC-VET Programme</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item href="https://www.oicvet.org/" className="menu-item"> <CaretRightOutlined /> Main Page</NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/files/article/693.pdf" className="menu-item"> <CaretRightOutlined /> OIC-TVET Strategic Roadmap 2020-2025</NavDropdown.Item>
                      <NavDropdown.Item href="http://www.oicvet.org/mac.php" className="menu-item"> <CaretRightOutlined /> Monitoring and Advisory Committee (MAC)</NavDropdown.Item>
                      <NavDropdown.Item href="http://www.oicvet.org/index.php" className="menu-item"> <CaretRightOutlined /> Activities</NavDropdown.Item>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>




              <NavDropdown title="Publications" id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row style={{ justifyContent: 'center' }}> 
                    {/* Technical Reports for Ministerial Meeting */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Technical Reports for Ministerial Meeting</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-aer.php" className="menu-item"> <CaretRightOutlined />OIC Economic Outlook</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-sdgs.php" className="menu-item"> <CaretRightOutlined />Annual Progress Report on Prioritized SDGs</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-water.php" className="menu-item"> <CaretRightOutlined />OIC Water Report</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-women-development-report.php" className="menu-item"> <CaretRightOutlined />OIC Women and Development Report</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-tourism.php" className="menu-item"> <CaretRightOutlined />International Tourism in the OIC</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-environment-report.php" className="menu-item"> <CaretRightOutlined />OIC Environment Report</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-health.php" className="menu-item"> <CaretRightOutlined />OIC Health Report</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-labour.php" className="menu-item"> <CaretRightOutlined />OIC Labour Market Report</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-state-youth.php" className="menu-item"> <CaretRightOutlined />State of Youth in OIC Member States</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-agriculture.php" className="menu-item"> <CaretRightOutlined />Agriculture and Food Security in OIC Member Countries</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-education.php" className="menu-item"> <CaretRightOutlined />Education and Scientific Development in OIC Countries</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publication-oic-family-values.php" className="menu-item"> <CaretRightOutlined />Safeguarding Family Values and the Institution of Marriage in OIC Countries</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-state-elderly-report.php" className="menu-item"> <CaretRightOutlined />State of Elderly in OIC Member Countries</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-state-children-report.php" className="menu-item"> <CaretRightOutlined />State of Children in OIC Member Countries</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-disabilities-special-needs.php" className="menu-item"> <CaretRightOutlined />People with Disabilities and Special Needs</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/article/693.pdf" className="menu-item"> <CaretRightOutlined />OIC-TVET Strategic Roadmap 2020-2025</NavDropdown.Item>
                    </Col>

                    {/* Regular/Series Publications */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Regular/Series Publications</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-syb.php" className="menu-item"> <CaretRightOutlined />Statistical Yearbook on OIC Member Countries</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-jecd.php" className="menu-item"> <CaretRightOutlined />Journal of Economic Cooperation and Development</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-enewsletter-training.php" className="menu-item"> <CaretRightOutlined />Training Cooperation e-Newsletter</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-newsletter-oicstat.php" className="menu-item"> <CaretRightOutlined />SESRIC Statistics Newsletter</NavDropdown.Item>
                    </Col>

                    {/* Strategic Documents */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Strategic Documents</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/article/601.pdf" className="menu-item"> <CaretRightOutlined />OIC Labour Market Strategy 2025</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/article/610.pdf" className="menu-item"> <CaretRightOutlined />Strategic Roadmap for Development of Islamic Tourism</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/article/480.pdf" className="menu-item"> <CaretRightOutlined />OIC Strategic Health Programme of Action 2014-2023</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/Youth-Strategy.pdf" className="menu-item"> <CaretRightOutlined />The OIC Youth Strategy</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/OPAAW.PDF" className="menu-item"> <CaretRightOutlined />OIC Plan of Action for the Advancement of Women (OPAAW)</NavDropdown.Item>
                    </Col>

                    {/* Outlook Reports and Others */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Outlook Reports</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-outlook.php" className="menu-item"> <CaretRightOutlined />OIC Outlook Series</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oso.php" className="menu-item"> <CaretRightOutlined />OIC Statistical Outlook</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-swot.php" className="menu-item"> <CaretRightOutlined />SWOT Outlook on OIC Member Countries</NavDropdown.Item>
                    </Col>

                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">Others</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/independent-reports.php" className="menu-item"> <CaretRightOutlined />Independent Reports</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/joint-reports.php" className="menu-item"> <CaretRightOutlined />Joint Reports</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/commissioned-reports.php" className="menu-item"> <CaretRightOutlined />Commissioned Reports</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/SESRIC-Brochure-EN.pdf" className="menu-item"> <CaretRightOutlined />SESRIC Brochure</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/e-newsletter.php" className="menu-item"> <CaretRightOutlined />SESRIC E-Newsletter</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-earchive.php" className="menu-item"> <CaretRightOutlined />e-Archive</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/imgs/news/Price-List.xls" className="menu-item"> <CaretRightOutlined />Price List</NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-orderform.php" className="menu-item"> <CaretRightOutlined />Order Form</NavDropdown.Item>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>

              <NavDropdown title="News Center" id="news-center-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row className="justify-content-md-center">
                    {/* News Center Content */}
                    <Col md={4} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">News Center</h3>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/calendar.php" className="menu-item"> <CaretRightOutlined /> Calendar</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/activities-archive.php" className="menu-item"> <CaretRightOutlined /> Activities Archive</NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/activities-announcements.php" className="menu-item"> <CaretRightOutlined /> Announcements</NavDropdown.Item>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>
              <Nav.Link style={{ color: '#2F83C5', fontSize: '15px' }} href="https://www.sesric.org/contact-info.php">Contact Us</Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}