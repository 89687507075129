// TabMenu.js
import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import '../../style/external/tabMenu.css';

const MenuStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: '10px', 
    borderBottomRightRadius: '10px',
    fontFamily: 'Roboto, sans-serif',

}



const TitleStyle = {
    backgroundColor: '#2F83C5', // This is the blue color used for the div
    color: 'white',
    padding: '10px',
    textAlign: 'center',
    width: '100%', // Assuming full width
    fontWeight: 'bold',
    fontSize: '20px',
    padding:'15px 0px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    fontFamily: 'Roboto, sans-serif'
  };

const LinkStyle = {
    textDecoration: 'none', 
};


const TabMenu = () => {
    const { pathname } = useLocation();

    const feedbackLink = 'https://forms.office.com/Pages/ResponsePage.aspx?id=0g_k-8nh50ufrIYE7PV4eO-XmOeis8xGtcYXE3_OvNRUN0lITTFQSzdTOE5ZNko3OTJUSk41N1ZXNiQlQCN0PWcu';
    const items = [
        { key: '/', label: <Link to={`/`} style={LinkStyle}>Home</Link>},
        { key: '/query', label: <Link to={`/query`} style={LinkStyle}>Query</Link> },
        { key: '/features', label: <Link to={`/features`} style={LinkStyle}>Features</Link>},
        { key: '/indicators_list', label: <Link to={`/indicators_list`} style={LinkStyle}>Indicators List</Link>},
        { key: '/data_source', label: <Link to={`/data_source`} style={LinkStyle}>Data Source</Link>},
        {
            key: '/feedback',
            label: <a style={LinkStyle} href="https://forms.office.com/Pages/ResponsePage.aspx?id=0g_k-8nh50ufrIYE7PV4eO-XmOeis8xGtcYXE3_OvNRUN0lITTFQSzdTOE5ZNko3OTJUSk41N1ZXNiQlQCN0PWcu" target="_blank" rel="noopener noreferrer">Feedback</a>,
        },
    ]
    const selectedKeys = items.filter(item => pathname.split('/').slice(0, 2).join('/') === item.key).map(item => item.key);
    
    return (
        <div style={{padding:'10px', borderRadius:'10px'}}>
            <div style={TitleStyle} >
                OIC Statistics Database (OICStat)
            </div>
            <Menu className='tab-menu' style={MenuStyle} mode="horizontal" selectedKeys={selectedKeys} defaultSelectedKeys={['1']} items={items} />
        </div>
    );
};
export default TabMenu;