import { Row, Col, Card, Typography } from 'antd';
import '../../style/external/dataSource.css';
const { Text } = Typography;

const baseURL = `${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_API_URL}static/data_source_images/`;


export function DataSource () {

  const logos = [
    { name: 'Centre for Research on the Epidemiology of Disasters (CRED)', src: 'https://www.sesric.org/imgs/CRED_EMDAT.jpg', url: 'https://public.emdat.be/' },
    { name: 'Clarivate Analytics, Web of Science', src: 'https://www.sesric.org/imgs/CLARIVATE ANALYTICS.png', url: 'https://access.clarivate.com/login?app=wos&alternative=true&shibShireURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fauth%3DShibboleth&shibReturnURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fmode%3DNextgen%26action%3Dtransfer%26path%3D%252Fwos%26DestApp%3DUA&referrer=mode%3DNextgen%26path%3D%252Fwos%26DestApp%3DUA%26action%3Dtransfer&roaming=true' },
    { name: 'Food and Agriculture Organization (FAO)', src: 'https://www.sesric.org/imgs/FAO_FAOSTAT.jpg', url: 'http://www.fao.org' },
    { name: 'Global Carbon Atlas', src: 'https://www.sesric.org/imgs/GLOBAL_CARBON_ATLAS.jfif', url: 'http://www.globalcarbonatlas.org/en/CO2-emissions' },
    { name: 'International Energy Agency (IEA)', src: 'https://www.sesric.org/imgs/IEA.jpg', url: 'https://www.iea.org' },
    { name: 'Islamic Financial Services Board (IFSB)', src: 'https://www.sesric.org/imgs/IFSB.png', url: 'https://www.ifsb.org' },
    { name: 'International Labour Organization (ILO)', src: 'https://www.sesric.org/imgs/ILO.png', url: 'https://www.ilo.org' },
    { name: 'International Monetary Fund (IMF)', src: 'https://www.sesric.org/imgs/IMF.png', url: 'https://www.imf.org' },
    { name: 'International Renewable Energy Agency (IRENA)', src: 'https://www.sesric.org/imgs/IRENA.png', url: 'https://www.irena.org/' },
    { name: 'International Organization of Motor Vehicle Manufacturers (OICA)', src: 'https://www.sesric.org/imgs/OICA.jpg', url: 'http://www.oica.net/' },
    { name: 'International Telecommunication Union (ITU)', src: 'https://www.sesric.org/imgs/ITU.png', url: 'https://www.itu.int' },
    { name: 'International Union for Conservation of Nature (IUCN)', src: 'https://www.sesric.org/imgs/IUCN.png', url: 'https://www.iucn.org' },
    { name: 'Joint United Nations Programme on HIV/AIDS (UNAIDS)', src: 'https://www.sesric.org/imgs/UNAIDS.jpg', url: 'https://www.unaids.org/en' },
    { name: 'Organization of Petroleum Exporting Countries (OPEC)', src: 'https://www.sesric.org/imgs/OPEC.png', url: 'https://www.opec.org/opec_web/en/' },
    { name: 'Organization for Economic Cooperation and Development (OECD)', src: 'https://www.sesric.org/imgs/OECD.png', url: 'http://www.oecd.org/' },
    { name: 'United Nations Educational, Scientific and Cultural Organization Institute for Statistics (UNESCO, UIS)', src: 'https://www.sesric.org/imgs/UNESCO_UIS.png', url: 'http://uis.unesco.org' },
    { name: 'United Nations Environment Programme (UNEP)', src: 'https://www.sesric.org/imgs/UNEP.png', url: 'https://web.unep.org/' },
    { name: 'United Nations Conference on Trade and Development (UNCTAD)', src: 'https://www.sesric.org/imgs/UNCTAD.jpg', url: 'https://www.unctad.org/' },
    { name: 'United Nations Development Programme Human Development Report Office (UNDP HDRO)', src: 'https://www.sesric.org/imgs/UNDP_HDRO.png', url: 'http://hdr.undp.org/' },
    { name: 'United Nations Children’s Fund (UNICEF)', src: 'https://www.sesric.org/imgs/UNICEF.jpg', url: 'https://www.unicef.org/' },
    { name: 'United Nations Industrial Development Organization (UNIDO)', src: 'https://www.sesric.org/imgs/UNIDO.png', url: 'https://www.unido.org/' },
    { name: 'United Nations Office on Drugs and Crime (UNODC)', src: 'https://www.sesric.org/imgs/UNODC.png', url: 'https://www.unodc.org' },
    { name: 'United Nations Population Division (UNPD)', src: 'https://www.sesric.org/imgs/UNPD.jpg', url: 'https://www.un.org/development/desa/pd/' },
    { name: 'United Nations Statistics Division (UNSD)', src: 'https://www.sesric.org/imgs/UNSD.png', url: 'https://unstats.un.org/home/' },
    { name: 'United States Department of Agriculture Foreign Agricultural Service (USDA FAS)', src: 'https://www.sesric.org/imgs/USDA_FAS.jpg', url: 'https://apps.fas.usda.gov/psdonline/app/index.html#/app/home' },
    { name: 'World Bank', src: 'https://www.sesric.org/imgs/WORLD_BANK.png', url: 'https://www.worldbank.org/en/home' },
    { name: 'World Health Organization (WHO)', src: 'https://www.sesric.org/imgs/WHO.png', url: 'https://www.who.int' },
    { name: 'World Intellectual Property Organization (WIPO)', src: 'https://www.sesric.org/imgs/WIPO.jpg', url: 'https://www.wipo.int/portal/en/index.html' },
    { name: 'World Steel Association (worldsteel)', src: 'https://www.sesric.org/imgs/WORLDSTEEL.png', url: 'https://www.worldsteel.org/' },
    { name: 'World Trade Organization (WTO)', src: 'https://www.sesric.org/imgs/WTO.png', url: 'https://www.wto.org/' },
    { name: 'World Tourism Organization (UNWTO)', src: 'https://www.sesric.org/imgs/UNWTO.png', url: 'https://www.unwto.org' },
  ];

  const logosWithBaseURL = logos.map(logo => ({
    name: logo.name,
    src: logo.src.replace('https://www.sesric.org/imgs/', baseURL),
    url: logo.url
  }));
  

  return (
    <div className="logo-grid" style={{ backgroundColor: 'white', margin:'10px', borderRadius:'10px'}}>
      <div style={{ padding: '20px' }}>
        <Text style={{ fontSize: '14px', fontFamily:'Roboto, sans-serif', fontWeight:'300' }}>
          To ensure data completeness and methodological comparability among the OIC member countries, 
          the OICStat Database currently presents data on the OIC member countries extracted from the databases of leading international organisations. 
          These include the following:
        </Text>
      </div>
      
      <Row gutter={[16, 16]} style={{ padding:'0px 30px' }}> 
        {logosWithBaseURL.map((logo, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
            <a href={logo.url || '#'} target="_blank" rel="noopener noreferrer">
              <Card hoverable className="logo-card">
                <div className="logo-container">
                  <img src={logo.src} alt={logo.name} style={{ maxWidth: '100%', display: 'block', margin: 'auto' }} />
                  <div className="logo-title">{logo.name}</div>
                </div>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
}